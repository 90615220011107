<template>
<!-- Main Content -->
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Edit Project</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="#"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Posts</a>
                    </li>
                    <li class="breadcrumb-item active">Edit Post</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">

                        <form @submit.prevent="editPost">

                            <div class="row clearfix">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Post Title" v-model="form.title" />
                                        <small class="text-danger" v-if="errors.title">{{ errors.title[0] }}*</small>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">

                                        <multiselect v-model="form.category" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="--Category--" :options="categories" :searchable="true" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong>
                                            </template>
                                        </multiselect>
                                        <small class="text-danger" v-if="errors.cat_id">{{ errors.cat_id[0] }}*</small>

                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="file" class="form-control" placeholder="Category Image" @change="onFileSelected($event)" />
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <!-- <textarea class="form-control" placeholder="Description" v-model="form.description" rows="3"></textarea> -->
                                        <vue-editor v-model="form.description"></vue-editor>
                                    </div>

                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary"  :disabled="loading ? 'disabled' : false">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    data() {
        return {
            form: {
                title: null,
                image: null,
                new_image: null,
                category: null,
                description: null,
            },
            loading: false,
            categories: [],
            errors: [],
        };
    },
    methods: {
         categoryList() {
            this.loading = true
            axios.get('/api/admin/blog-category/')
                .then((response) => {
                    this.categories = response.data

                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        singlePost() {
            this.loading = true
            axios.get('/api/admin/blog/' + this.$route.params.id)
                .then((response) => {
                    this.form = response.data
                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        onFileSelected(event) {
            let file = event.target.files[0];
            if (file.size > 1224048770) {
                this.$vToastify.error("Image too large");
                return;
            } else {
                let reader = new FileReader();
                reader.onload = (event) => {
                    this.form.new_image = event.target.result;
                    console.log(event.target.result);
                };
                reader.readAsDataURL(file);
            }
        },
        editPost() {
            this.loading = true;
            axios
                .put("/api/admin/blog/" + this.$route.params.id, this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    this.form.name = null;
                    this.$router.push({
                        name: "post-list",
                    });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.singlePost()
        this.categoryList()
    }
};
</script>
