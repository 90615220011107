<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Location List
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <router-link :to="{name:'location-add'}" class="btn btn-primary btn-icon btn-round hidden-sm-down float-right m-l-10" type="button">
                    <i class="zmdi zmdi-plus p-2"></i>
                </router-link>
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Locations</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">

                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width:10%">S/N</th>
                                            <th style="width:60%"> Name </th>
                                            <!-- <th style="width:40%"> Address </th> -->
                                            <th style="width:10%"> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(location, index) in locations" :key="location.id">
                                            <td scope="row">{{ index+1 }}</td>
                                            <td>{{location.name}}</td>

                                            <!-- <td>{{location.address}}</td> -->
                                            <td>
                                                <button class="btn btn-primary btn-sm" @click="deletelocation(location.slug)"> <i class="zmdi zmdi-delete"></i> </button>

                                                <router-link :to="{name:'location-edit', params: {id: location.slug },}" class="btn btn-primary btn-sm"> <i class="zmdi zmdi-edit"></i> </router-link>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
export default {
    data() {
        return {

            loading: false,
            errors: [],
            locations: []

        }

    },
    methods: {

        locationList() {
            this.loading = true
            axios.get('/api/admin/location/')
                .then((response) => {
                    this.locations = response.data

                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        deletelocation(slug) {
            this.$confirm({
                message: `Are you sure?`,
                title: 'Information',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm 
                 */
                callback: confirm => {
                    if (confirm) {
                        axios.delete('/api/admin/location/' + slug).then((response) => {
                            this.$vToastify.success(response.data.message);

                            Fire.$emit('after-action')
                        }).catch((error) => {
                            this.$notify({
                                type: "error",
                                duration: 10000,
                                text: error
                            });

                        })

                    }
                }
            })
        },
    },
    mounted() {
        this.locationList()
        Fire.$on("after-action", () => {
            this.locationList()
        });
    }
}
</script>
