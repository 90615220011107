<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Application List</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Applications</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th>S/N</th>
                                            <th>name</th>

                                            <th>Email</th>
                                            <th>Phone</th>

                                            <th>Address</th>
                                            <th>CV</th>
                                            <th>Other</th>

                                            <th>Date</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(job, index) in jobs.data" :key="job.id">
                                            <td scope="row">{{ index + 1 }}</td>
                                            <td>{{ job.name }}</td>
                                            <td>{{ job.email }}</td>
                                            <td>{{ job.phone }}</td>
                                            <td>{{ job.address }}</td>
                                            <td>

                                                <form :action="'/api/admin/job/cv-download/' + job.id" id="download" method="post" v-if="job.cv">
                                                    <input type="hidden" name="_token" :value="csrf" />

                                                    <button class="btn btn-sm btn-default"> <i class="zmdi zmdi-download"></i>
                                                    </button>

                                                </form>
                                            </td>
                                            <td>
                                                <form :action="'/api/admin/job/other-download/' + job.id" id="download" method="post" v-if="job.other_file">
                                                    <input type="hidden" name="_token" :value="csrf" />
                                                     <button class="btn btn-sm btn-default"> <i class="zmdi zmdi-download"></i>
                                                    </button>
                                                </form>
                                            </td>

                                            <td>{{ job.updated_at | moment("YYYY-MM-DD") }}</td>
                                            <td>
                                                <router-link :to="{name:'job-edit', params: {id: job.id },}" class="btn btn-primary btn-sm"> <i class="zmdi zmdi-eye"></i> </router-link>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            jobs: [],
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
        };
    },
    methods: {
        jobList() {
            this.loading = true;
            axios
                .get("/api/admin/jobs/")
                .then((response) => {
                    this.jobs = response.data;
                })
                .catch((error) => {
                    this.$vToastify.error("Something went wrong");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.jobList();
        Fire.$on("after-action", () => {
            this.jobList();
        });
    },
};
</script>
