<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Property List</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <router-link :to="{ name: 'property-add' }" class="
              btn btn-primary btn-icon btn-round
              hidden-sm-down
              float-right
              m-l-10
            " type="button">
                    <i class="zmdi zmdi-plus p-2"></i>
                </router-link>
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Properties</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width: 5%">S/N</th>
                                            <th style="width: 10%">Image</th>
                                            <th style="width: 20%">Name</th>
                                            <th style="width: 15%">Price</th>
                                            <th style="width: 15%">Location</th>
                                            <th style="width: 20%">Address</th>
                                            <th>Featured</th>
                                            <th style="width: 40%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(pro, index) in properties.data" :key="pro.id">
                                            <td scope="row">{{ index + 1 }}</td>
                                            <td>
                                                <img :src="'/' + pro.cover_image" alt="" srcset="" style="width: 80; height: 80px" />
                                            </td>
                                            <td>{{ pro.name }}</td>
                                            <td>{{ pro.price }}</td>
                                            <td>{{ pro.location ? pro.location.name : "" }}</td>
                                            <td>{{ pro.address }}</td>
                                            <td>
                                                <div class="custom-control custom-switch custom-switch-xl">
                                                    <input @change="propertyStatusForm(pro.id, $event)" type="checkbox" class="custom-control-input" :id="'customSwitch-' + pro.id" :value="pro.id" :true-value="1" :false-value="0" v-model="featured[index]" />
                                                    <label class="custom-control-label" :for="'customSwitch-' + pro.id">
                                                        <!-- <small> Inctive</small> -->
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <button class="btn btn-primary btn-sm" @click="deletepro(pro.id)">
                                                    <i class="zmdi zmdi-delete"></i>
                                                </button>
                                                <router-link :to="{name: 'property-edit', params: { slug: pro.slug },}" class="btn btn-primary btn-sm">
                                                    <i class="zmdi zmdi-eye"></i>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                 <div class="m-auto">
                                        <pagination :data="properties" @pagination-change-page="propertyList" page="1"></pagination>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            properties: {},
            pro: [],

            featured: [{}],
            form: {
                featured: null,
            },
        };
    },
    methods: {
        propertyStatusForm(id, evt) {
            this.form.featured = evt.target.checked;
            axios
                .put("/api/admin/property/update-status/" + id, this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    Fire.$emit("after-action");

                })
                .catch((error) => {
                    this.errors = error.response.data.errors;

                    this.$vToastify.error("Something went wrong");
                });
        },
        propertyList(page=1) {
            this.loading = true;
            axios
                .get("/api/admin/property?page=" + page)
                .then((response) => {
                    this.properties = response.data;
                    this.pro = response.data.data;
                    this.pro.forEach((pro, index) => {
                        this.featured[index] = pro.featured;
                    });
                })
                .catch((error) => {
                    this.$vToastify.error("Something went wrong");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        deletepro(id) {
            this.$confirm({
                message: `Are you sure?`,
                title: "Information",
                button: {
                    no: "No",
                    yes: "Yes",
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: (confirm) => {
                    if (confirm) {
                        axios
                            .delete("/api/admin/property/" + id)
                            .then((response) => {
                                this.$vToastify.success(response.data.message);

                                Fire.$emit("after-action");
                            })
                            .catch((error) => {
                                this.$notify({
                                    type: "error",
                                    duration: 10000,
                                    text: error,
                                });
                            });
                    }
                },
            });
        },
    },
    mounted() {
        this.propertyList();
        Fire.$on("after-action", () => {
            this.propertyList();
        });
    },
};
</script>
