<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Project List
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <router-link :to="{name:'post-create'}" class="btn btn-primary btn-icon btn-round hidden-sm-down float-right m-l-10" type="button">
                    <i class="zmdi zmdi-plus p-2"></i>
                </router-link>
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Posts</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">

                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">

                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width:10%">S/N</th>
                                            <th style="width:10%"> Image </th>
                                            <th style="width:20%"> title </th>
                                            <th style="width:10%"> Category </th>
                                            <th style="width:10%"> Created By </th>
                                            <th style="width:10%"> Date Posted </th>

                                            <th style="width:15%"> Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(post, index) in posts.data" :key="post.id">
                                            <td scope="row">{{ index+1 }}</td>
                                            <td><img :src="'/'+post.image" alt="" srcset="" style="width:80;height:80px"></td>
                                            <td>{{post.title}}</td>
                                            <td>{{post.category ? post.category.name : ''}}</td>
                                            <td>{{post.admin.full_name}}</td>
                                            <td>{{post.created_at|moment('Y-m-d')}}</td>

                                            <td>
                                                <button class="btn btn-primary btn-sm" @click="deletepost(post.id)"> <i class="zmdi zmdi-delete"></i> </button>

                                                <router-link :to="{name:'post-edit', params: {id: post.id },}" class="btn btn-primary btn-sm"> <i class="zmdi zmdi-edit"></i> </router-link>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
export default {
    data() {
        return {

            loading: false,
            errors: [],
            posts: []

        }

    },
    methods: {

        postList() {
            this.loading = true
            axios.get('/api/admin/blog/')
                .then((response) => {
                    this.posts = response.data

                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        deletepost(id) {
            this.$confirm({
                message: `Are you sure?`,
                title: 'Information',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm 
                 */
                callback: confirm => {
                    if (confirm) {
                        axios.delete('/api/admin/blog/' + id).then((response) => {
                            this.$vToastify.success(response.data.message);

                            Fire.$emit('after-action')
                        }).catch((error) => {
                            this.$notify({
                                type: "error",
                                duration: 10000,
                                text: error
                            });

                        })

                    }
                }
            })
        },
    },
    mounted() {
        this.postList()
        Fire.$on("after-action", () => {
            this.postList()
        });
    }
}
</script>
