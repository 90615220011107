<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Subscriber List</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Subcribers</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width: 10%">S/N</th>
                                            <th style="width: 50%">Email</th>
                                            <th style="width: 10%">Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(subcriber, index) in subcribers.data" :key="subcriber.id">
                                            <td scope="row">{{ index + 1 }}</td>
                                            <td>{{ subcriber.email }}</td>
                                            <td>
                                                {{ subcriber.updated_at | moment("YYYY-MM-DD") }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="m-auto">
                                    <pagination :data="subcribers" @pagination-change-page="subscriberList" page="1"></pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            subcribers: {},
        };
    },
    methods: {
        subscriberList(page = 1) {
            this.loading = true;
            axios
                .get("/api/admin/subscribers/?page=" + page)
                .then((response) => {
                    this.subcribers = response.data;
                })
                .catch((error) => {
                    this.$vToastify.error("Something went wrong");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.subscriberList();
        Fire.$on("after-action", () => {
            this.subscriberList();
        });
    },
};
</script>
