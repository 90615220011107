<template>
<form class="form" @submit.prevent="loginAction">
    <div class="header">
        <div class="logo-container">
            <img src="assets/images/logo.svg" alt="">
        </div>

        <h5>Are you an Agent ?</h5>
        <p>Log In</p>
    </div>
    <div class="content">
        <div class="input-group input-lg">
            <input type="email" class="form-control" placeholder="Enter User Name" v-model="form.email">
            <span class="input-group-addon">
                <i class="zmdi zmdi-account-circle"></i>
            </span>
        </div>
        <div class="input-group input-lg">
            <input type="password" placeholder="Password" class="form-control" v-model="form.password" />
            <span class="input-group-addon">
                <i class="zmdi zmdi-lock"></i>
            </span>
        </div>
    </div>
    <div class="footer text-center">

        <button class="btn l-cyan btn-round btn-lg btn-block waves-effect waves-light" :disabled="loading ? 'disabled' : false">
            <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
            </div> SIGN IN
        </button>
        <h6 class="m-t-20"><a :href="'/forgot-password'" class="link">Forgot Password?</a></h6>
    </div>
</form>
</template>

<script>
export default {
    data() {
        return {
            form: {
                email: null,
                password: null,

            },
            errors: [],
            loading: false
        }
    },
    methods: {
        loginAction() {
            this.loading = true
            axios.post('/api/agent-login', this.form).then((response) => {
                this.$vToastify.success(response.data.message);
                window.setTimeout(function () {
                    setTimeout("window.location.href='/agent'", 2000);
                }, 500);

            }).catch((error) => {
                this.errors = error.response.data
                this.$vToastify.error(error.response.data.message);
                this.loading = true

            }).finally(() => {
                this.loading = false

            })

        }
    }
}
</script>
