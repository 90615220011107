<template>
<!-- Main Content -->
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Edit Location
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a href="index.html"><i class="zmdi zmdi-home"></i> Centrebase</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Locations</a></li>
                    <li class="breadcrumb-item active">Edit Location</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">

                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <form @submit.prevent="editLocation">
                            <div class="row clearfix">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Location Name" v-model="form.name">
                                        <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}*</small>

                                    </div>
                                </div>
                                <!-- <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="tet" class="form-control" placeholder="Location Address" v-model="form.address">
                                    </div>
                                </div> -->
                            </div>

                            <button type="submit" class="btn btn-primary"  :disabled="loading ? 'disabled' : false">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: null,
                address: null,
            },
            loading: false,
            errors: []

        }

    },
    methods: {
        singleLocation() {
            console.log(this.$route.params.id, 'IDDDD')
            this.loading = true
            axios.get('/api/admin/location/' + this.$route.params.id)
                .then((response) => {
                    this.form = response.data
                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },

        editLocation() {
            this.loading = true
            axios.put('/api/admin/location/' + this.$route.params.id, this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    this.form.name = null
                    this.$router.push({
                        name: 'location-list'
                    })

                }).catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);

                }).finally(() => {
                    this.loading = false;

                })
        },

    },
    mounted() {
        this.singleLocation()
    }
}
</script>
