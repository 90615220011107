<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Application List</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Applications</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-4">
                                <div class="card">
                                    <p> <strong> Name : </strong> {{job.name}}</p>
                                    <p> <strong> Email : </strong> {{job.email}}</p>
                                    <p> <strong> Phone : </strong> {{job.phone}}</p>
                                    <p> <strong> State : </strong> {{job.state}}</p>
                                    <p> <strong> Town : </strong> {{job.town}}</p>

                                </div>
                            </div>

                            <div class="col-4">
                                <div class="card border-primary">
                                    <p> <strong> Address : </strong> {{job.address}}</p>
                                    <p> <strong> Qualification : </strong> {{job.qualification}}</p>
                                    <p> <strong> Availablity Date : </strong> {{job.availability_date}}</p>
                                    <p> <strong> Enquiry : </strong> {{job.enquiry}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            job: null
        };
    },
    methods: {
        singleJob() {
            console.log(this.$route.params.id, 'IDDDD')
            this.loading = true
            axios.get('/api/admin/job/' + this.$route.params.id)
                .then((response) => {
                    this.job = response.data
                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;
                })
        },
    },
    mounted() {
        this.singleJob();

    },
};
</script>
