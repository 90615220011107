<template>
<div class="col-md-12 content-center">
    <div class="card-plain" style="background:rgba(5, 5, 5, 0.527);padding:20px;border-radius:5px;width;max-width:600px !important">
        <form class="form" @submit.prevent="registerAction">
            <div class="header">
                <div class="logo-container">
                    <img src="assets/images/logo.svg" alt="" />
                </div>
                <h5>Register As An Agent</h5>
            </div>
            <div class="content">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="status == 200">
                    You need to Confirm your email
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="text" placeholder="First Name" class="form-control" v-model="form.fname" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-account-circle"></i>
                            </span>
                        </div>
                        <small class="text-danger" v-if="errors.fname">{{ errors.fname[0] }}*</small>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="text" placeholder="Last Name" class="form-control" v-model="form.lname" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-account-circle"></i>
                            </span>
                        </div>
                        <small class="text-danger" v-if="errors.lname">{{ errors.lname[0] }}*</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="text" placeholder="Company Name" class="form-control" v-model="form.company_name" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-accounts"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="email" placeholder="Email" class="form-control" v-model="form.email" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-email"></i>
                            </span>
                        </div>
                        <small class="text-danger" v-if="errors.email">{{ errors.email[0] }}*</small>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="text" placeholder="Phone Number" class="form-control" v-model="form.phone" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-lock"></i>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="file" class="form-control" @change="onFileSelected($event)" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-collection-image-o"></i>
                            </span>
                        </div>
                        <small class="text-muted" style="padding-top: 0px !important">Upload profile picture</small>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="password" placeholder="Password" class="form-control" v-model="form.password" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-lock"></i>
                            </span>
                        </div>
                        <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}*</small>
                    </div>
                    <div class="col-md-6">
                        <div class="input-group input-lg">
                            <input type="password" placeholder="Password Confirmation" class="form-control" v-model="form.password_confirmation" />
                            <span class="input-group-addon">
                                <i class="zmdi zmdi-lock"></i>
                            </span>
                        </div>
                        <small class="text-danger" v-if="errors.password_confirmation">{{ errors.password_confirmation[0] }}*</small>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="input-groupnput-lg">
                            <textarea class="form-control p-3" name="" id="" rows="3" placeholder="Address" v-model="form.address"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer text-center">
                <button class="
              btn
              l-cyan
              btn-round btn-lg btn-block
              waves-effect waves-light
            ">
                    <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>

                    REGISTER
                </button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                fname: null,
                lname: null,
                phone: null,
                address: null,
                password: null,
                password_confirmation: null,
                email: null,
                password: null,
                company_name: null,
                image: null
            },
            status: null,
            errors: [],
            loading: false,
        };
    },
    methods: {
        registerAction() {
            this.loading = true;
            axios
                .post("/api/agent-register", this.form)
                .then((response) => {
                    this.status = response.status
                    this.$vToastify.success(response.data.message);
                    window.setTimeout(function () {
                        setTimeout("window.location.href='/agent/login'", 2000);
                    }, 500);
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);
                    this.loading = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        onFileSelected(event) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (event) => {
                this.form.image = event.target.result;
                console.log(event.target.result);
            };
            reader.readAsDataURL(file);
        },
    },
};
</script>
