<template>
<div class="row justify-content-center" style="">
    <div class="col-md-7 col-lg-5">
        <div class="login-wrap p-4 p-md-5">
            <div class="icon d-flex align-items-center justify-content-center">
                <span class="fa fa-user-o"></span>
            </div>
            <h3 class="text-center mb-4">Sign In</h3>
            <form @submit.prevent="loginAction" class="login-form">
                <div class="form-group">
                    <input type="text" class="form-control rounded-left" placeholder="Username" v-model="form.email">
                    <small class="text-danger" v-if="errors.email">{{ errors.email[0] }}*</small>

                </div>
                <div class="form-group d-flex">
                    <input type="password" class="form-control rounded-left" placeholder="Password" v-model="form.password">
                </div>
                <div class="form-group">
                    <button type="submit" class="form-control btn btn-primary rounded submit px-3" :disabled= "loading ? 'disabled' : false">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
                        </div> 
                        Login
                    </button>
                </div>
                <div class="form-group d-md-flex">
                    <div class="w-50">
                        <label class="checkbox-wrap checkbox-primary">Remember Me
                            <input type="checkbox" checked v-model="form.remember">
                            <span class="checkmark"></span>
                        </label>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>

<script>
export default {
    data() {
        return {
            form: {
                email: null,
                password: null,
                remember: null,

            },
            errors: [],
            loading: false
        }
    },
    methods: {
        loginAction() {
            this.loading = true
            axios.post('/api/admin-login', this.form).then((response) => {
                this.$vToastify.success(response.data.message);
                window.setTimeout(function () {
                    setTimeout("window.location.href='/admin'", 2000);
                }, 500);

            }).catch((error) => {
                this.errors = error.response.data
                this.$vToastify.error(error.response.data.message);
                            this.loading = true


            }).finally(() => {
            this.loading = false

            })

        }
    }
}
</script>
