<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Messages List</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Messages</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width: 5%">S/N</th>
                                            <th style="width: 15%">Name</th>
                                            <th style="width: 25%">Email</th>
                                            <th style="width: 15%">Phone No</th>
                                            <th style="width: 25%">Message</th>
                                            <th style="width: 25%">Date Posted</th>

                                            <th style="width: 20%">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(message, index) in messages.data" :key="message.id">
                                            <td scope="row">{{ index + 1 }}</td>
                                            <td>{{ message.data['fromName'] }}</td>

                                            <td>{{ message.data['fromEmail'] }}</td>
                                            <td>{{ message.data['fromPhone'] }}</td>
                                            <td>{{ message.data['body'] }}</td>

                                            <td>
                                                {{ message.updated_at  | moment("from", "now", true) }} ago
                                            </td>

                                            <td>
                                                <button class="btn btn-primary btn-sm" type="button" @click="deleteMessage(message.id)">
                                                    <i class="zmdi zmdi-delete"></i>
                                                </button>

                                                <button class="btn btn-info btn-sm" type="button" @click="readMessage(message.id)" v-if="!message.read_at">
                                                    Read
                                                </button>
                                                <button class="btn btn-default btn-sm" type="button" v-else disabled style="cursor:default">
                                                    <i>Marked</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="m-auto">
                                    <pagination :data="messages" @pagination-change-page="messageList" page="1"></pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            messages: {},
        };
    },
    methods: {
        readMessage(id) {
            axios.post('/api/auth/read/message/' + id).then((response) => {
                this.$vToastify.success(response.data.message);

                Fire.$emit('after-action')
            }).catch((error) => {
                this.$notify({
                    type: "error",
                    duration: 10000,
                    text: error
                }).finally(() => {
                    this.$notify({
                        type: "error",
                        duration: 10000,
                        text: 'Network error'
                    });
                })

            })
        },
        deleteMessage(id) {
            this.$confirm({
                message: `Are you sure?`,
                title: 'Information',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm 
                 */
                callback: confirm => {
                    if (confirm) {
                        axios.delete('/api/admin/messages/' + id).then((response) => {
                            this.$vToastify.success(response.data.message);

                            Fire.$emit('after-action')
                        }).catch((error) => {
                            this.$notify({
                                type: "error",
                                duration: 10000,
                                text: error
                            });

                        })

                    }
                }
            })
        },
        messageList(page = 1) {
            this.loading = true;
            axios
                .get("/api/admin/messages/?page=" + page)
                .then((response) => {
                    this.messages = response.data;
                })
                .catch((error) => {
                    this.$vToastify.error("Something went wrong");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.messageList();
        Fire.$on("after-action", () => {
            this.messageList();
        });
    },
};
</script>
