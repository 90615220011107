<template>
<div class="card">
    <div class="header">
        <h2><strong>Profile</strong> Settings</h2>
    </div>
    <div class="body">
        <form action="" @submit.prevent="submitProfile" method="post">

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="First Name" v-model="form.fname">
                        <small class="text-danger" v-if="errors.fname">{{ errors.fname[0] }}*</small>

                    </div>

                </div>
                <div class="col-6">

                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Last Name" v-model="form.lname">
                        <small class="text-danger" v-if="errors.lname">{{ errors.lname[0] }}*</small>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Last Name" v-model="form.email" readonly>

                    </div>

                </div>
                <div class="col-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Company Name" v-model="form.company_name">
                    </div>
                </div>
            </div>

            <div class="form-group">
                <input type="file" class="form-control" @change="onFileSelected">
            </div>
            <div class="form-group">
                <input type="password" class="form-control" placeholder="Current Password" v-model="form.current_password">

            </div>
            <div class="form-group">
                <input type="password" class="form-control" placeholder="New Password" v-model="form.password">
                <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}*</small>

            </div>

            <div class="form-group">
                <input type="password" class="form-control" placeholder="Confirm Password" v-model="form.password_confirmation">
            </div>

            <div class="form-group">
                <textarea class="form-control" name="" id="" rows="3" placeholder="Address" v-model="form.address">
                              </textarea>
            </div>
            <button class="btn btn-info btn-round" type="submit">Save Changes</button>
        </form>
    </div>
</div>
</template>

<script>
export default {
    props: {
        agent: {
            type: {
                Object
            }
        }
    },
    data() {
        return {
            form: {
                fname: null,
                lname: null,
                phone: null,
                address: null,
                current_password: null,
                password: null,
                password_confirmation: null,
                email: null,
                password: null,
                company_name: null,
                image: null,
                new_image:null
            },
            errors: [],
            loading: false,
        }
    },
    methods: {
        clearFields() {
            this.form.fname = null
            this.form.lname = null
            this.form.phone = null
            this.form.address = null
            this.form.password = null
            this.form.company_name = null
            this.form.password_confirmation = null

        },

        onFileSelected(event) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (event) => {
                this.form.new_image = event.target.result;
                console.log(event.target.result);
            };
            reader.readAsDataURL(file);
        },
        submitProfile() {
            this.errors = []
            axios
                .put("/api/auth/profile-update/", this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                     setTimeout(() => {
                        location.reload()
                    }, 500);

                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);
                });
        },
    },
    mounted() {
        this.form = this.agent
    }
}
</script>
