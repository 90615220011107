export let routes = [

    {
        path: '/admin',
        name: 'dashboard',
        component: require('./components/Dashboard.vue').default
    },

    {
        path: '/admin/category/add',
        name: 'category-add',
        component: require('./components/category/Add.vue').default
    },{
        path: '/admin/category/list',
        name: 'category-list',
        component: require('./components/category/List.vue').default
    },

    {
        path: '/admin/category/edit/:id',
        name: 'category-edit',
        component: require('./components/category/Edit.vue').default
    },

    {
        path: '/admin/property/create',
        name: 'property-add',
        component: require('./components/property/Add.vue').default
    },

    {
        path: '/admin/properties',
        name: 'property-list',
        component: require('./components/property/List.vue').default
    },
    {
        path: '/admin/property/:slug',
        name: 'property-edit',
        component: require('./components/property/Edit.vue').default
    },


    {
        path: '/admin/location/add',
        name: 'location-add',
        component: require('./components/location/Add.vue').default
    },{
        path: '/admin/location/list',
        name: 'location-list',
        component: require('./components/location/List.vue').default
    },

    {
        path: '/admin/location/edit/:id',
        name: 'location-edit',
        component: require('./components/location/Edit.vue').default
    },


    {
        path: '/admin/banner/add',
        name: 'banner-add',
        component: require('./components/banner/Add.vue').default
    },{
        path: '/admin/banner/list',
        name: 'banner-list',
        component: require('./components/banner/List.vue').default
    },

    {
        path: '/admin/banner/edit/:id',
        name: 'banner-edit',
        component: require('./components/banner/Edit.vue').default
    },


    {
        path: '/admin/subscriber/list',
        name: 'subscriber-list',
        component: require('./components/subscriber/List.vue').default
    },

    {
        path: '/admin/contact/list',
        name: 'contact-list',
        component: require('./components/contactUs/List.vue').default
    },


    {
        path: '/admin/jobs',
        name: 'career-list',
        component: require('./components/career/List.vue').default
    },

    {
        path: '/admin/job/:id',
        name: 'job-edit',
        component: require('./components/job/View.vue').default
    },


    //
    {
        path: '/admin/blog/category/add',
        name: 'post-category-create',
        component: require('./components/blog/PostCategoryAdd.vue').default
    }, 
    {
        path: '/admin/blog/category',
        name: 'post-category-list',
        component: require('./components/blog/PostCategoryList.vue').default
    },
    {
        path: '/admin/blog/category/edit/:id',
        name: 'post-category-edit',
        component: require('./components/blog/PostCategoryEdit.vue').default
    },

    {
        path: '/admin/blog',
        name: 'post-list',
        component: require('./components/blog/List.vue').default
    },

    {
        path: '/admin/blog/create',
        name: 'post-create',
        component: require('./components/blog/Add.vue').default
    },

    {
        path: '/admin/blog/:id',
        name: 'post-edit',
        component: require('./components/blog/Edit.vue').default
    },

    {
        path: '/admin/profile',
        name: 'profile-edit',
        component: require('./components/profile/Edit.vue').default
    },

    {
        path: '/admin/agent',
        name: 'agent-list',
        component: require('./components/agent/List.vue').default
    },

    {
        path: '/admin/agent/:id',
        name: 'agent-edit',
        component: require('./components/agent/Edit.vue').default
    },

    {
        path: '/admin/add/agent',
        name: 'agent-add',
        component: require('./components/agent/Add.vue').default
    },

    {
        path: '/admin/messages',
        name: 'admin-messages',
        component: require('./components/Message.vue').default
    },

]
