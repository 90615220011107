<template>
<div>
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Dashboard
                    <small class="text-muted">Welcome to Centre Base Consult</small>
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <button class="btn btn-primary btn-icon btn-round hidden-sm-down float-right m-l-10" type="button">
                    <i class="zmdi zmdi-plus"></i>
                </button>
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a href="/admin"><i class="zmdi zmdi-home"></i> Centre Base Consult</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-3 col-md-6">
                <div class="card">
                    <div class="body">
                        <h3 class="number count-to" data-from="0" data-to="128" data-speed="2000" data-fresh-interval="700">{{prop_count}}</h3>
                        <p class="text-muted">Properties</p>
                        <div class="progress">
                            <div class="progress-bar l-blue" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100" :style="'width:'+prop_count + '%'"></div>
                        </div>
                        <!-- <small>Change 27%</small> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card">
                    <div class="body">
                        <h3 class="number count-to" data-from="0" data-to="758" data-speed="2000" data-fresh-interval="700">{{agents}}</h3>
                        <p class="text-muted">Registered Agents</p>
                        <div class="progress">
                            <div class="progress-bar l-green" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100" :style="'width:'+agents + '%'">></div>
                        </div>
                        <!-- <small>Change 9%</small> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card">
                    <div class="body">
                        <h3 class="number count-to" data-from="0" data-to="2521" data-speed="2000" data-fresh-interval="700">{{subscribers}}</h3>
                        <p class="text-muted">Subscribers</p>
                        <div class="progress">
                            <div class="progress-bar l-amber" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100" :style="'width:'+subscribers + '%'"></div>
                        </div>
                        <!-- <small>Change 17%</small> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="card">
                    <div class="body">
                        <h3>{{contacts}}</h3>
                        <p class="text-muted">Enquiries</p>
                        <div class="progress">
                            <div class="progress-bar l-parpl" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100" :style="'width:'+contacts + '%'"></div>
                        </div>
                        <!-- <small>Change 13%</small> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <h2><strong>Properties</strong> </h2>
                        <ul class="header-dropdown">
                            <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i class="zmdi zmdi-more"></i> </a>
                                <ul class="dropdown-menu dropdown-menu-right slideUp float-right">
                                    <li><a href="javascript:void(0);">Edit</a></li>
                                    <li><a href="javascript:void(0);">Delete</a></li>
                                    <li><a href="javascript:void(0);">Report</a></li>
                                </ul>
                            </li>
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>

                    <!--  -->
                    <table class="table table-striped">
                        <thead class="thead-inverse">
                            <tr>
                                <th style="width: 5%">S/N</th>
                                <th style="width: 10%">Image</th>
                                <th style="width: 20%">Name</th>
                                <th style="width: 15%">Price</th>
                                <th style="width: 15%">Location</th>
                                <th style="width: 20%">Address</th>
                                <th>Featured</th>
                                <th style="width: 40%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(pro, index) in properties.data" :key="pro.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>
                                    <img :src="'/' + pro.cover_image" alt="" srcset="" style="width: 80; height: 80px" />
                                </td>
                                <td>{{ pro.name }}</td>
                                <td>{{ pro.price }}</td>
                                <td>{{ pro.location ? pro.location.name : "" }}</td>
                                <td>{{ pro.address }}</td>
                                <td>
                                    <div class="custom-control custom-switch custom-switch-xl">
                                        <input @change="propertyStatusForm(pro.id, $event)" type="checkbox" class="custom-control-input" :id="'customSwitch-' + pro.id" :value="pro.id" :true-value="1" :false-value="0" v-model="featured[index]" />
                                        <label class="custom-control-label" :for="'customSwitch-' + pro.id">
                                            <!-- <small> Inctive</small> -->
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-primary btn-sm" @click="deletepro(pro.id)">
                                        <i class="zmdi zmdi-delete"></i>
                                    </button>
                                    <router-link :to="{name: 'property-edit', params: { slug: pro.slug },}" class="btn btn-primary btn-sm">
                                        <i class="zmdi zmdi-eye"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            contacts: null,
            prop_count: null,
            properties: {},
            agents: null,
            subscribers: null,

            pro: [],

            featured: [{}],
            form: {
                featured: null,
            },

        };
    },
    methods: {
        propertyStatusForm(id, evt) {
            this.form.featured = evt.target.checked;
            axios
                .put("/api/admin/property/update-status/" + id, this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    Fire.$emit("after-action");

                })
                .catch((error) => {
                    this.errors = error.response.data.errors;

                    this.$vToastify.error("Something went wrong");
                });
        },
        dashboardData() {
            this.loading = true;
            axios
                .get("/api/admin/dashboard-data/")
                .then((response) => {
                    this.contacts = response.data.contact;
                    this.prop_count = response.data.prop_count;
                    this.agents = response.data.agents;
                    this.subscribers = response.data.subscribers;

                    this.properties = response.data.properties;
                    this.pro = response.data.properties.data;
                    this.pro.forEach((pro, index) => {
                        this.featured[index] = pro.featured;
                    });

                })
                .catch((error) => {
                    this.$vToastify.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        deletepro(id) {
            this.$confirm({
                message: `Are you sure?`,
                title: "Information",
                button: {
                    no: "No",
                    yes: "Yes",
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: (confirm) => {
                    if (confirm) {
                        axios
                            .delete("/api/admin/property/" + id)
                            .then((response) => {
                                this.$vToastify.success(response.data.message);

                                Fire.$emit("after-action");
                            })
                            .catch((error) => {
                                this.$notify({
                                    type: "error",
                                    duration: 10000,
                                    text: error,
                                });
                            });
                    }
                },
            });
        },
    },
    mounted() {
        this.dashboardData();
        Fire.$on("after-action", () => {
            this.dashboardData();
        });

    },
};
</script>
