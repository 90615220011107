<template>
<!-- Main Content -->
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Update Profile</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="#"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Profile</a>
                    </li>
                    <li class="breadcrumb-item active">Edit Profile</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">

                        <form action="" @submit.prevent="submitProfile" method="post">

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="First Name" v-model="form.name">
                                        <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}*</small>

                                    </div>

                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Last Name" v-model="form.email" readonly>

                                    </div>

                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Last Name" v-model="form.phone">

                                    </div>

                                </div>

                            </div>
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Company Name" v-model="form.company_name">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="file" class="form-control" @change="onFileSelected">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Current Password" v-model="form.current_password">

                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="New Password" v-model="form.password">
                                        <small class="text-danger" v-if="errors.password">{{ errors.password[0] }}*</small>

                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <input type="password" class="form-control" placeholder="Confirm Password" v-model="form.password_confirmation">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <textarea class="form-control" name="" id="" rows="3" placeholder="Address" v-model="form.address">
                              </textarea>
                            </div>
                            <button class="btn btn-info btn-round" type="submit" :disabled="loading ? 'disabled' : false">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>Save Changes
                            </button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: null,
                phone: null,
                address: null,
                current_password: null,
                password: null,
                password_confirmation: null,
                email: null,
                password: null,
                company_name: null,
                image: null,
                new_image: null
            },
            loading: false,
            errors: [],
        };
    },
    methods: {
        clearFields() {
            this.form.name = null
            this.form.phone = null
            this.form.address = null
            this.form.password = null
            this.form.company_name = null
            this.form.password_confirmation = null

        },

        getAdmin() {
            this.loading = true
            axios.get('/api/admin/single')
                .then((response) => {
                    this.form = response.data
                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },

        onFileSelected(event) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (event) => {
                this.form.new_image = event.target.result;
                console.log(event.target.result);
            };
            reader.readAsDataURL(file);
        },
        submitProfile() {
            this.loading = true;

            this.errors = []
            axios
                .put("/api/admin/update/", this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    setTimeout(() => {
                        location.reload()
                    }, 500);

                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);
                }).finally(() => {
                    this.loading = false;

                })
        },
    },
    mounted() {
        this.getAdmin()
    }
};
</script>
