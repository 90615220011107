<template>
<div class="custom-control custom-switch custom-switch-xl">
    <input @change="propertyStatusForm(pro.id, $event)" type="checkbox" class="custom-control-input" :id="'customSwitch-' + pro.id" :value="pro.id" :true-value="1" :false-value="0" v-model="featured[index]" />
    <label class="custom-control-label" :for="'customSwitch-' + pro.id">
        <!-- <small> Inctive</small> -->
    </label>
</div>
</template>

<script>
export default {
    props:{
        pro:{
            type:{
                Object
            }
        }
    },
    data() {
        return {
            featured: [{}],
            form: {
                featured: null,
            },
        }
    },
    methods: {
        propertyStatusForm(id, evt) {
            this.form.featured = evt.target.checked;
            axios
                .put("/api/admin/property/update-status/" + id, this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    Fire.$emit("after-action");

                })
                .catch((error) => {
                    this.errors = error.response.data.errors;

                    this.$vToastify.error("Something went wrong");
                });
        },
    }
}
</script>
