<template>
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Contact Us List</h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item">
                        <a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">
                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-striped">
                                    <thead class="thead-inverse">
                                        <tr>
                                            <th style="width: 5%">S/N</th>
                                            <th style="width: 10%">Name</th>
                                            <th style="width: 20%">Email</th>
                                            <th style="width: 10%">subject</th>
                                            <th style="width: 5%">Telephone</th>
                                            <th style="width: 20%">Message</th>

                                            <th style="width: 10%">Date Posted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contact, index) in contacts.data" :key="contact.id">
                                            <td scope="row">{{ index + 1 }}</td>
                                            <td>{{ contact.name }}</td>
                                            <td>{{ contact.email }}</td>

                                            <td>{{ contact.subject }}</td>
                                            <td>{{ contact.tel }}</td>
                                            <td>{{ contact.message }}</td>

                                            <td>
                                                {{ contact.updated_at | moment("YYYY-MM-DD") }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                 <div class="m-auto">
                                    <pagination :data="contacts" @pagination-change-page="contactList" page="1"></pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            errors: [],
            contacts: {},
        };
    },
    methods: {
        contactList(page=1) {
            this.loading = true;
            axios
                .get("/api/admin/contact-us?page=" + page)
                .then((response) => {
                    this.contacts = response.data;
                })
                .catch((error) => {
                    this.$vToastify.error("Something went wrong");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.contactList();
        Fire.$on("after-action", () => {
            this.contactList();
        });
    },
};
</script>
