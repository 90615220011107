/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
import {routes} from "./route"
import VueRouter from "vue-router";
import VueToastify from "vue-toastify";
Vue.use(VueToastify);

// Components
import Vue from 'vue'
window.Fire = new Vue();
Vue.use(require("vue-moment"));



// Advanced Use - Hook into Quill's API for Custom Functionality
import { VueEditor, Quill } from "vue2-editor";

import VueConfirmDialog from 'vue-confirm-dialog'
 
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
import Multiselect from 'vue-multiselect'

Vue.component('multiselect', Multiselect)

Vue.component("pagination", require("laravel-vue-pagination"));

Vue.component("login", require('./components/Login.vue').default);
Vue.component("agent-login", require('./components/AgentLogin.vue').default);
Vue.component("agent-register", require('./components/AgentRegister.vue').default);

Vue.component("loading", require('./components/Loading.vue').default);

Vue.component("property-create", require('./components/property/Add.vue').default);
Vue.component("property-edit", require('./components/PropertyEditComponent.vue').default);

Vue.component("update-status", require('./components/UpdateStatus.vue').default);

Vue.component("profile-settings", require('./components/ProfileSettings.vue').default);

 Vue.use(VueRouter);

 const router = new VueRouter({
    routes,
    mode: "history"
});
const app = new Vue({
    el: '#app',
    router,

});

