<template>
<!-- Main Content -->
<section class="agent">
    <div class="block-header">
        <div class="row">
            <div class="col-lg-7 col-md-6 col-sm-12">
                <h2>Edit Property
                </h2>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">

                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a href="/admin"><i class="zmdi zmdi-home"></i> Centrebase</a></li>
                    <li class="breadcrumb-item"><a href="/admin/properties">Properties</a></li>
                    <li class="breadcrumb-item active">Edit Property</li>
                </ul>
            </div>
        </div>
    </div>

    <!-- 
                $table->string('name'); ?
            $table->string('property_id');?
            $table->string('bedroom')->nullable();?
            $table->string('bathrooms')->nullable();?
            $table->string('area')->nullable();?
            $table->string('garage')->nullable();?
            $table->string('garage_size')->nullable();?
            $table->string('location_id')->nullable() ?;
            $table->string('address')->nullable();?
            $table->string('slug');
            $table->date('year_built')->nullable();?
            $table->string('property_type')->nullable() ??;
            $table->text('features')->nullable();
            $table->text('nearby')->nullable();
            $table->string('price');??
            $table->unsignedBigInteger('admin_id')->nullable(); //rent ??
            $table->foreign('admin_id')->references('id')->on('admins')->onDelete('cascade');??

            $table->string('property_status')->default('sale'); //rent ?
            $table->unsignedBigInteger('category_id')->nullable(); //rent ??
            $table->foreign('category_id')->references('id')->on('property_categories')->onDelete('cascade'); ??

            $table->unsignedBigInteger('agent_id')->nullable();??
            $table->foreign('agent_id')->references('id')->on('agents')->onDelete('cascade'); -->

    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="card">
                    <div class="header">
                        <ul class="header-dropdown">

                            <li class="remove">
                                <a role="button" class="boxs-close"><i class="zmdi zmdi-close"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <form @submit.prevent="submitForm">
                            <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    <span class="sr-only">Close</span>
                                </button>
                                <strong>Note!</strong> All fields with asterisks are required
                                before submitting form.
                            </div>
                            <div class="row clearfix">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Name*" v-model="form.name" required />
                                        <small class="text-danger" v-if="errors.name">{{ errors.name[0] }}*</small>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="Price*" v-model="form.price" required />
                                        <small class="text-danger" v-if="errors.price">{{ errors.price[0] }}*</small>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div>
                                        <multiselect v-model="form.category" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="--Category--" :options="categories" :searchable="true" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row clearfix">
                                <div class="col-sm-6">
                                    <multiselect v-model="form.location" :options="locations" track-by="name" label="name" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Location" :allow-empty="false"></multiselect>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Address*" v-model="form.address" />
                                    </div>
                                </div>
                                <!-- <div class="col-sm-4">
                                    <multiselect v-model="form.property_type" :options="property_types" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Property Status e.g Rent, Sale"></multiselect>
                                </div> -->
                            </div>

                            <div class="row clearfix mb-2">
                                <div class="col-sm-4">
                                    <multiselect v-model="form.property_status" :options="property_status" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Property Status e.g Rent, Sale"></multiselect>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="No of bedroooms" v-model="form.bedrooms" />
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="No of bathrooms" v-model="form.bathrooms" />
                                    </div>
                                </div>
                            </div>

                            <div class="row clearfix mb-2">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="Area e.g 4300" v-model="form.area" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Area unit e.g Sq ft, Metres" v-model="form.area_unit" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="No of garage" v-model="form.garage" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="No of garage" v-model="form.garage_size" />
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input type="number" class="form-control" placeholder="No of rooms" v-model="form.rooms" />
                                    </div>
                                </div>
                            </div>

                            <!--  -->
                            <div class="row clearfix mb-2">
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Year built" v-model="form.year_built" />
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <input type="file" class="form-control" placeholder="Cover Image" @change="onFileSelected($event)" />
                                    <small class="text-muted">Cover Image</small>
                                </div>

                                <div class="col-sm-4">
                                    <input type="file" class="form-control" id="upload-file" placeholder="Images" @change="uploadImage($event)" multiple />
                                    <small class="text-muted">Upload Multiple Images</small>
                                </div>
                            </div>

                            <div class="row clearfix">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <vue-editor v-model="form.description"></vue-editor>
                                    </div>
                                </div>
                            </div>

                            <div class="row clearfix">
                                <div class="col-sm-6">
                                    <div class="form-group" v-for="(a, index) in form.features" :key="index">
                                        <div style="margin-bottom: 20px">
                                            <div class="input-group mb-3 input-group-sm">
                                                <input v-model="form.features[index]" type="text" class="form-control" name="" id="" placeholder="Features" aria-describedby="fileHelpId" />
                                                <span class="input-group-append">
                                                    <button class="btn btn-danger" type="button" @click="deleteFeature(index)">
                                                        X
                                                    </button>
                                                    <button class="btn btn-info" type="button" @click="addFeature(index)">
                                                        Add Feature
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group" v-for="(a, index) in form.nearby" :key="index">
                                        <div style="margin-bottom: 20px">
                                            <div class="input-group mb-3 input-group-sm">
                                                <input v-model="form.nearby[index]" type="text" class="form-control" name="" id="" placeholder="Places Near By" aria-describedby="fileHelpId" />
                                                <span class="input-group-append">
                                                    <button class="btn btn-danger" type="button" @click="deleteNearby">
                                                        X
                                                    </button>
                                                    <button class="btn btn-add" type="button" @click="addNearby(index)">
                                                        Add Near By
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary" :disabled="loading ? 'disabled' : false">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div> Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

<style>
.btn-simple {
    display: none !important
}

.bootstrap-select {
    -webkit-border-radius: none !important;
    -moz-border-radius: none !important;
    -ms-border-radius: none !important;
    border-radius: 1 !important;
}

.showtick {
    font-size: 0.9em !important
}
</style>

<script>
import {
    VueEditor
} from "vue2-editor";

export default {
    components: {
        VueEditor
    },
    data() {
        return {

            form: {
                name: '',
                cover_image: '',
                price: '',
                bedrooms: '',
                bathrooms: '',
                rooms: '',
                area: '',
                area_unit: "",

                garage: '',
                garage_size: '',
                location: '',
                address: '',
                year_built: '',
                property_type: '',
                property_status: '',
                category: '',
                images: [],
                description: '',

                features: [''],
                nearby: [''],
            },
            property_status: ['Sale', 'Rent','Lease'],
            locations: [],
            property_types: ['Residential', 'Commercial', 'Land', 'Luxury'],

            loading: false,
            errors: [],
            categories: [],

        }

    },
    methods: {
        locationList() {
            this.loading = true
            axios.get('/api/admin/location/')
                .then((response) => {
                    this.locations = response.data

                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        singleProperty() {
            this.loading = true
            axios.get('/api/admin/property/' + this.$route.params.slug)
                .then((response) => {
                    this.form = response.data
                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        categoryList() {
            this.loading = true
            axios.get('/api/admin/category/')
                .then((response) => {
                    this.categories = response.data.data

                }).catch((error) => {
                    this.$vToastify.error("Something went wrong");

                }).finally(() => {
                    this.loading = false;

                })
        },
        submitForm() {
            this.loading = true

            let forms = new FormData
            if (this.form.images != undefined) {
                for (let i = 0; i < this.form.images.length; i++) {
                    forms.append('images[]', this.form.images[i]);
                }

            }
            forms.append('name', this.form.name)
            forms.append('price', this.form.price)
            forms.append('address', this.form.address)
            forms.append('bedrooms', this.form.bedrooms)
            forms.append('bathrooms', this.form.bathrooms)
            forms.append('rooms', this.form.rooms)

            forms.append('area', this.form.area)
            forms.append("area_unit", this.form.area_unit);
            forms.append("description", this.form.description);

            forms.append('garage', this.form.garage)
            forms.append('garage_size', this.form.garage_size)
            forms.append('year_built', this.form.year_built)
            forms.append('property_type', this.form.property_type)
            forms.append('property_status', this.form.property_status)

            if (this.form.location) {
                forms.append('location_id', this.form.location.id)
            }

            if (this.form.category) {
                forms.append('category_id', this.form.category.id)
            }

            if (this.form.cover_image) {
                forms.append('cover_image', this.form.cover_image[0])
            }
            forms.append('images', this.form.images)
            this.form.features.forEach((value, index) => {
                forms.append('features[]', this.form.features[index])
            })
            this.form.nearby.forEach((value, index) => {
                forms.append('nearby[]', this.form.nearby[index])
            })
            console.log(this.form, "gdgdgdggg")

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            document.getElementById('upload-file').value = [];
            axios.post('/api/auth/property/' + this.$route.params.slug, forms, config).then(response => {
                    //success
                    this.$vToastify.success(response.data.message);
                    this.$router.push({
                        name: 'property-list'
                    })
                    console.log(response);
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);
                }).finally(() => {
                    this.loading = false

                })

        },
        uploadImage(e) {
            this.form.images = []
            let selectedFiles = e.target.files;
            if (!selectedFiles.length) {
                return false;
            }
            for (let i = 0; i < selectedFiles.length; i++) {
                this.form.images.push(selectedFiles[i]);
            }
            console.log(this.form.images);
        },
        addFeature() {
            this.form.features.push('Add Another Feature Here');
        },
        deleteFeature(index) {
            if (this.form.features.length == 1) {
                return;
            } else {
                this.form.features.splice(index, 1);
            }
        },

        addNearby() {
            this.form.nearby.push('Add Another Near By Here');
        },
        deleteNearby(index) {
            if (this.form.nearby.length == 1) {
                return;
            } else {
                this.form.nearby.splice(index, 1);
            }
        },
        onFileSelected(e) {
            let selectedFiles = e.target.files;
            if (!selectedFiles.length) {
                return false;
            }
            this.form.cover_image = selectedFiles

        },
        addCat() {
            this.loading = true
            axios.post('/api/admin/category/', this.form)
                .then((response) => {
                    this.$vToastify.success(response.data.message);
                    this.form.name = null
                    this.$router.push({
                        name: 'category-list'
                    })

                }).catch((error) => {
                    this.errors = error.response.data.errors;
                    this.$vToastify.error(error.response.data.message);

                }).finally(() => {
                    this.loading = false;

                })
        }
    },
    mounted() {
        this.categoryList()
        this.singleProperty()
        this.locationList()

    }
}
</script>
